require("./bootstrap");
// const axios = require('axios');
import lightGallery from "lightgallery";

// import $ from "jquery";

// window.$ = window.jQuery = $;

// require("lightgallery");
lightGallery(document.getElementById("reviews-lightgallery"), {
    thumbnail: true,
});
